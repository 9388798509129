<template>
  <v-select solo 
  prepend-inner-icon="mdi-calendar"
  :items="$helpers.showDate()"
  v-model="$store.state[store].date"
  v-on:input="searchDate($event)"/>
</template>

<script>
export default {
  props: ['store','action'],
  methods: {
    searchDate(event) {
      var vm = this
      vm.$store.dispatch(`${vm.store}/${vm.action}`, {
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: event,
        count: vm.$store.state[vm.store].count,
        status: vm.$store.state[vm.store].status,
      })
    },
  }
}
</script>